import { makeStyles } from "@material-ui/core/styles"

const height = "2.5rem" // 40px
const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: height,
    maxHeight: height,
  },
  headlineWrapper: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  whatsappLink: {
    textDecoration: "none",
    color: "#FFF",
  },
  phoneIcon: {
    marginRight: 3,
  },
}))
export default useStyles
