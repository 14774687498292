import React from "react"
import { Link } from "gatsby"
import { Box, Typography, Container } from "@material-ui/core"
import useStyles from "./style"

// import WhatsappImg from "../../images/whatsapp-img.svg"
import PhoneIcon from "../../images/phone-icon.svg"

const Headline = () => {
  const classes = useStyles()
  return (
    <Box width="100%" className={classes.root}>
      <Box width="80%" display="flex" justifyContent="flex-end" alignItems="center" className={classes.headlineWrapper}>
        {/* <Link to="https://api.whatsapp.com/send?phone=6281908126242&text=Chat+with+cura" className={classes.whatsappLink}> */}
          {/* <Box display="inline-flex" alignItems="center">
            <img src={PhoneIcon} className={classes.phoneIcon}/>
            <Typography variant="body2" className={classes.whatsappLink}>(+62) 21-2251-3230</Typography>
          </Box> */}
        {/* </Link> */}
      </Box>
    </Box>
  )
}

export default Headline
