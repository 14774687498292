import React from 'react'
import Headline from "../Headline"
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { Box, Typography, AppBar, Toolbar, IconButton, Hidden, Drawer, List, ListItem  } from '@material-ui/core'
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import useStyles from './style'
import CuraPalmLogo from '../../images/curapalm-logo.svg'

const Navbar = () => {
    const classes = useStyles()
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const currentRoute = useLocation().pathname;
    const toggleDrawer = () => setOpenDrawer(!openDrawer)
    const menus = [
        { label:"home", href:"/" },
        { label:"products", href:"/products" },
        { label:"market range", href:"/market-range" },
        { label:"contact", href:"/contact" },
    ]

    const drawer = (
        <Box role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer} >
            <div className={classes.drawerHeader}>
                <Box display="flex">
                    <Box>
                        <IconButton onClick={toggleDrawer}>
                        <CloseIcon className={classes.closeButton} />
                        </IconButton>
                    </Box>
                    <Box className={classes.logoWrapper} textAlign="center">
                        <Link to="/">
                            <img
                                src={CuraPalmLogo}
                                className={classes.mainLogoDrawer}
                            />
                        </Link>
                    </Box>
                </Box>
            </div>

            <List>
                {menus.map( (menu,index) =>
                    <ListItem>
                        <Box key={index} className={classNames( currentRoute === menu.href ? classes.active : undefined)}>
                            <Link to={menu.href} className={classes.menuLink}>
                                <Typography variant="body1">
                                    {menu.label}
                                </Typography>
                            </Link>
                        </Box>
                    </ListItem>
                )}
            </List>

        </Box>

    )

  
    return (
        <React.Fragment>
            <AppBar
                position="static"
                className={classes.appBar}
                elevation={0}
            > 
                <Toolbar className={classes.toolBar}>
                    <IconButton
                        aria-label="Open Navigation Drawer"
                        edge="start"
                        onClick={toggleDrawer}
                        className={classes.menuButton}
                        >
                    <MenuIcon />
                    </IconButton>
                    <Box width="80%" display="inline-flex" justifyContent="space-between" alignItems="center" className={classes.navbarWrapper}>
                        <Box>
                            <Link to="/">
                                <img src={CuraPalmLogo} alt="curapalm-logo" className={classes.mainLogo}/>
                            </Link>
                        </Box>
                        <Box className={classes.menuList} display="inline-flex">
                            {menus.map( (menu,index) =>
                                <Box key={index} className={classNames(classes.menuItem, currentRoute === menu.href ? classes.active : undefined)}>
                                    <Link to={menu.href} className={classes.menuLink}>
                                        <Typography variant="body1">
                                            {menu.label}
                                        </Typography>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer}>
                <Hidden mdDown implementation="css">
                <Drawer
                    variant="temporary"
                    open={openDrawer}
                    onClose={toggleDrawer}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true,
                    }}
                >
                    {drawer}
                </Drawer>
                </Hidden>
            </nav>
        </React.Fragment>
    )
}

export default Navbar
