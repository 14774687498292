import { makeStyles } from '@material-ui/core/styles'

const footerHeight = "27.5rem" // 440px
const footerPadding = "6.25rem 9.375rem" // 100px 150px
const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        minHeight: footerHeight,
        padding:footerPadding,
        background:"#FAFAFA",
        [theme.breakpoints.down("md")]: {
            padding:'2rem'
        }
    },
    footerAppLogo:{
        [theme.breakpoints.down("md")]: {
            margin:'auto',
            marginBottom:'50px'
        }
    },
    contactWrapper:{
        [theme.breakpoints.down("md")]: {
            maxWidth:'100%',
            minWidth:'80%'
        }
    },
    footerWrapper:{
        borderBottom:"1px solid #C4C4C4",
        [theme.breakpoints.down("md")]: {
            flexDirection:'column'
        }
    }
}))
export default useStyles