import { makeStyles } from "@material-ui/core/styles"

const height = "5rem" // 80px
const menuItemHeight = "4.8rem" // 5rem-0.2rem
const menuMargin = 25
const drawerWidth = "100%"
const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up("sm")]: {
    // maxWidth: wrapperWidth,
  },
  appBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
  },
  toolBar: {
    display: "flex",
    width: "85%",
    justifyContent: "space-between",
  },
  navbarWrapper: {
    minHeight: height,
    maxHeight: height,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  menuButton: {
    // color: "#FFF",
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("md")]: {
      flexBasis: 0,
      display: "none !important",
    },
  },
  menuList: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  mainLogo:{
    width:190,
    height:50,
    // [theme.breakpoints.down("md")]: {
    //     display:'none'
    // }
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    marginLeft: menuMargin,
    minHeight: menuItemHeight,
  },
  menuLink: {
    textDecoration: "none",
    textTransform: "capitalize",
    color: "#000",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    padding: "40px 20px",
    // paddingLeft:'40px'
  },
  mainLogoDrawer:{
    width:190,
    height:50,
    // [theme.breakpoints.down("md")]: {
    //     display:'none'
    // }
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    // padding:"0 10px",
    margin: "0 10px",
    minHeight: height,
    maxHeight: height,
  },
  
  logoWrapper: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 15,
      right: 15,
      left: 15,
      bottom: 0,
    //   visibility: "visible",
    },
  },
  active: {
    "& > a": {
      color: theme.palette.primary.main,
    },
    borderBottom: "5px solid" + theme.palette.primary.main,
  },
}))
export default useStyles
