import React from "react"
import { Box, Typography } from "@material-ui/core"
import useStyles from "./style"
import AppLogo from "../../images/curapalm-logo.svg"
import EmailIcon from "../../images/email-icon.svg"
const Footer = (props) => {
  const classes = useStyles()
  const { address, emailAddress} = (props.data ? props.data : {address: 'adress', emailAddress: 'email'})
  return (
    <Box width="100%" className={classes.root}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        className={classes.footerWrapper}
        paddingBottom="2.5rem"
      >
        <Box className={classes.footerAppLogo}>
          <img src={AppLogo} alt="cura-app-logo" width="190"/>
        </Box>
        <Box maxWidth="25.25rem" minWidth="25.25rem" className={classes.contactWrapper}>
          <Box mb="1rem">
            <Typography variant="body1">Contact Detail</Typography>
          </Box>
          <Box mb="1rem">
            <Typography variant="body2">
              {address}
            </Typography>
          </Box>
          <Box display="inline-flex">
            <Box mr={1}>
              <img src={EmailIcon} alt="email-icon" width="24" />
            </Box>
            <Typography variant="body2">{emailAddress}</Typography>
          </Box>
        </Box>
      </Box>
      <Box py="2rem">
        <Typography variant="body2">
          © 2021 - Cura Palm Oil & Fats Pte Ltd. All Rights Reserved
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
